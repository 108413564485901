export interface Card {
    title: String,
    project: Project[],
    image: String
}

export interface Project {
    title: String,
    exposition: String,
    image: String,
    github: String
}

export const CardList: Card[] = [
    {
        title: "React",
        project: [
            {
                title: "실시간 통신을 이용한 메모장",
                exposition: `많은 메모장이 있지만 실시간 공유가 아닌 정적인 공유가 대부분이기에 실시간 통신을 할 수 있지않을까라는 생각에 개발을 진행했습니다.
                            개발 인원: 1명(100% 기여)
                            제작 기간: 2023.03 ~ 2023.06
                            주요 기술: React, HTML, CSS, MariaDB, PHP, Apache`,
                image: "https://raw.githubusercontent.com/seungwoo505/Notepads/main/mainScreen.png",
                github: "https://github.com/seungwoo505/Notepads"
            },
            {
                title: "포트폴리오",
                exposition: `현재 접속해있는 사이트입니다.
                            그리고 모바일 환경과 PC 환경 모두 동일한 UI, UX를 구축하는 것을 목표로 두어 개발을 진행했습니다.
                            개발 인원: 1명(100% 기여)
                            제작 기간: 2024.01 ~ 2024.02
                            주요 기술: React, TypeScript, Swiper, Framer Motion, EmailJS, Redux Toolkit, Apache`,
                image: "https://raw.githubusercontent.com/seungwoo505/Portfolio/main/mainScreen.png",
                github: "https://github.com/seungwoo505/Portfolio"
            },
            {
                title: "API Test",
                exposition: `API를 직접 제작을 해보고 REST API에 맞춰 통신을 해보는 것을 목표로 개발을 진행했습니다.
                            개발 인원: 1명(100% 기여)
                            제작 기간: 2024.03 ~ 2024.03
                            주요 기술: React, Node.js`,
                image: "https://raw.githubusercontent.com/seungwoo505/API-Test/main/mainScreen.png",
                github: "https://github.com/seungwoo505/API-Test"
            }
        ],
        image: "../../image/React.png"
    },
    {
        title: "ReactNative",
        project: [
            {
                title: "기억력 테스트 게임",
                exposition: `스마트폰의 등장으로 사람들이 기억을 안하게된다고하는데 그러면 역으로 기억을 하게 만드는 게임을 개발하면 기억력이 향상되지않을까라는 생각에 개발되었습니다.
                            개발 인원: 1명(100% 기여)
                            제작 기간: 2023.09 ~ 2023.11
                            주요 기술: React Native, React Navigation, MariaDB, PHP`,
                image: "https://raw.githubusercontent.com/seungwoo505/Memory/main/memory.png",
                github: "https://github.com/seungwoo505/Memory"
            },
            {
                title: "ToDoList",
                exposition: `작년에 만든 React Native 앱의 경우 처음 만드는 것이기도 하고 대학교 과제로써 제출되어야했기에 3개월이라는 짧은 공부와 개발을 동시에 이루어져야했습니다.
                            그러다보니 공부가 완전히 되지못한 것 같다라는 생각이 들었고 좀 더 공부를 해보자라는 의미로 개발 공부의 기초적인 부분이라 들 수 있는 ToDoList를 개발하게되었습니다.
                            개발 인원: 1명(100% 기여)
                            제작 기간: 2024.02 ~ 2024.02
                            주요 기술: React Native, AsyncStorage`,
                image: "https://raw.githubusercontent.com/seungwoo505/ToDoList-ver.RN-/main/IosScreen.png",
                github: "https://github.com/seungwoo505/ToDoList-ver.RN-"
            }
        ],
        image: "../../image/ReactNative.png"
    },
    {
        title: "Next",
        project: [
            {
                title: "회사 홈페이지 수정",
                exposition: `전에 만들었던 홈페이지를 수정한 버전입니다.
                            현재 페이지 상 MPA보다 SPA가 어울린다 생각되어 NextJS로 전면 수정하고 그 때 당시 남아있던 버그를 수정했습니다.
                            개발 인원: 2명(100% 기여)
                            제작 기간: 2023.07 ~ 2023.08
                            주요 기술: NextJS, TypeScript, Swiper, Framer Motion, Naver Maps API`,
                image: "https://raw.githubusercontent.com/seungwoo505/ECGM-edit-Version/main/mainScreen.png",
                github: "https://github.com/seungwoo505/ECGM-edit-Version"
            }
        ],
        image: "../../image/Next.png"
    },
    {
        title: "JS",
        project: [
            {
                title: "태양 위치에 따른 태양열패널 각도 예측 프로그램",
                exposition: `2019 동양미래EXPO에 전시된 제품은 외부에서 보여줘야 설명하기 편하지만
                            전시 특성상 내부에서 보여줘야하기 때문에 보다 쉽게 보여주기위해 만든 페이지입니다.
                            개발 인원: 12명(75% 기여)
                            제작 기간: 2019.06 ~ 2019.10
                            주요 기술: HTML, CSS, JavaScript, Three.js, Arduino`,
                image: "https://raw.githubusercontent.com/seungwoo505/2019COEX/master/page.png",
                github: "https://github.com/seungwoo505/2019COEX"
            },
            {
                title: "회사 홈페이지",
                exposition: `홈페이지를 만들어달라해서 만들어준 홈페이지입니다.
                            그 때 당시에는 MPA와 SPA의 개념이 명확하지못했어서 MPA를 SPA처럼 개발을 진행했었습니다.
                            추후 Next.js를 통해 전면 수정했습니다.
                            개발 인원: 2명(100% 기여)
                            제작 기간: 2019.11 ~ 2020.01
                            주요 기술: HTML, CSS, JavaScript, jQuery, Naver Maps API`,
                image: "https://raw.githubusercontent.com/seungwoo505/ECGM-Project/main/mainScreen.png",
                github: "https://github.com/seungwoo505/ECGM-project"
            },
            {
                title: "피아노 연습 페이지",
                exposition: `3학년 때 과제로 만든 페이지입니다.
                            과제가 아두이노와 폰을 자유롭게 연동하라는 것이였는데 주제가 명확히 정해져있지않아 모바일 환경 뿐만이 아닌 웹 환경에서도 작동될 수 있게하면 어떨까라는 생각에 개발되었습니다.
                            개발 인원: 3명(90% 기여)
                            제작 기간: 2022.05 ~ 2022.06
                            주요 기술: HTML, CSS, JavaScript, Arduino`,
                image: "https://raw.githubusercontent.com/seungwoo505/piano-practice/main/mainScreen.png",
                github: "https://github.com/seungwoo505/piano-practice"
            },
            {
                title: "웹 통신을 통한 RC카 조종",
                exposition: `3학년 때 과제로 만든 페이지입니다.
                            RC카를 웹 사이트를 통해 동작하게 하라는 자유 과제였는데 RC카의 기본은 사용자 자유롭게 동작하는 것이기에 기본에 충실하자라는 의미로 개발되었습니다.
                            개발 인원: 3명(100% 기여)
                            제작 기간: 2022.11 ~ 2022.12
                            주요 기술: HTML, CSS, JavaScript, Flask`,
                image: "https://raw.githubusercontent.com/seungwoo505/RC-Control/main/control.png",
                github: "https://github.com/seungwoo505/RC-Control"
            }
        ],
        image: "../../image/JS.png"
    },
    {
        title: "Vue",
        project: [
            {
            title: "Vue 연습",
            exposition: `Vue관련 프로젝트를 준비하려고 Vue 공부용 간단 프로젝트입니다.
                        개발 인원: 1명(100% 기여)
                        제작 기간: 2024.04 ~ 2024.04
                        주요 기술: Vue`,
            image: "https://raw.githubusercontent.com/seungwoo505/VuePractice/main/mainScreen.png",
            github: "https://github.com/seungwoo505/VuePractice"
            },
        ],
        image: "../../image/Vue.png"
    },
    {
        title: "Document",
        project: [
            {
                title: "",
                exposition: "",
                image: "",
                github: ""
            }
        ],
        image: "../../image/document.png"
    }
]